import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import Cookies from "universal-cookie";

import Header from "../header/headerSaudi-ar";
import Footer from "../footer/footerAr";
import HomeContent from "../home-content/homeContentAr";
import HomeTabs from "../home-tabs/homeTabsAr";
import Claim from "../claim/claimAr";
import ChatModal from "../Modals/chat-modalAr";

const Landing = () => {
  let ageCookie = new Cookies();
  const [popUp, setPopUp] = useState(false);

  useEffect(() => {
    if (!ageCookie.get("isOldEnough")) {
      setPopUp(false);
      navigate("/ar/agegate");
    } else {
      setPopUp(true);
      // navigate("/stores");
    }
  }, []);
  return (
    <>
      <Header />
      <section className="main-section bg-primary mb-4 mb-md-5">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-lg-6">
              <HomeContent />
            </div>
            <div className="col-lg-6 bg-secondary position-relative px-0 main-tabs">
              <HomeTabs />
            </div>
          </div>
        </div>
      </section>
      <Claim />
      <div className="footer-class" id="footer-class">
        <Footer />
      </div>
      {/* <df-messenger
        intent="WELCOME"
        chat-title="SnickersInsuranceChatbot"
        agent-id="b82be01c-2c46-40b8-b515-b428454db79a"
        language-code="en"
      ></df-messenger> */}
      <ChatModal show={popUp} onHide={() => setPopUp(false)} animation={true} />
    </>
  );
};

export default Landing;
